<template>
  <section id="grading" v-if="!loading">
    <v-text-field
      solo
      dense
      flat
      class="col-xl-3 col-lg-4 custom-border mb-5 general-custom-field f14 poppins fw500"
      hide-details
      prepend-inner-icon="mdi-magnify"
      placeholder="Search course"
      v-model="search"
    />
    <v-data-table
      :headers="grading_tbl"
      :items="grading"
      class="text--center custom-border poppins f14"
      :search="search"
      :page.sync="page"
      hide-default-footer
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event">
      <template v-slot:item.action="{ item }">
        <v-btn
          text
          class="primary--text text-capitalize"
          :to="{ name: 'Instructor Grading Course', params: { id: item.id, course_name: item.name } }"
        >
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
      </template>
    </v-data-table>
    <v-row align="center" class="my-2" v-if="grading.length > 0">
      <v-col lg="1" class="hidden-md-and-down">
        <v-text-field
          :value="itemsPerPage"
          label="Items"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>
      </v-col>

      <v-col lg="10" md="12">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>
  </section>
  <circular v-else/>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { grading_tbl } from '../../constants/tblheaders/grading'

export default {
  methods: {
    ...mapActions('instructor', ['getGrading'])
  },
  computed: {
    ...mapState('instructor', {
      grading(state) {
        let courses = []
        state.grading.forEach(item => {
          courses.push(
            { 
              id: item.id,
              title: item.title?item.title: 'Untitled', 
              image: item.image,
            }
          )
        })
        return courses
      }
    }),

    page: {
      get() {
        let page = parseInt(this.$route.query.page);
        return page;
      },
      set(page) {
        this.$router.replace({ query: { page: page, timestamp: Date.now() } });
      },
    },
  },
  mounted(){
    if(!this.$route.query || !this.$route.query.page){
      this.$router.replace({ query: { page: 1 , timestamp: Date.now() } });
    } else {
      this.$router.replace({ query: { page: this.$route.query.page , timestamp: Date.now() } });
    }
    this.getGrading().then(() => {
      this.loading = false
    })
  },
  data: () => ({
    grading_tbl,
    search: '',
    pageCount: 0,
    itemsPerPage: 5,
    loading: true
  }),

};
</script>

<style></style>

